import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";

import { updateUser } from "redux/actions/setUserActions";
import { Layout } from "components/Layout";
import { PersonalData } from "components/PersonalData";
import { ReactComponent as PersonSVG } from "../static/img/ic-person.svg";
import { ReactComponent as LockSVG } from "../static/img/ic-lock.svg";
import { YanaoComponent } from "helpers/YanaoComponent";

/**
 * @api {get} /settings настройка профиля пользователя
 * @apiGroup profile
 * @apiName настройка профиля пользователя
 *
 * @apiDescription <p>Страница настроек настройка профиля пользователя</p>
 * <p>Компонент <code>SettingsPage.js</code> <a href="../client/src/pages/SettingsPage.js">ссылка на файл</a></p>
 */

export const SettingsPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation("settings");
    const [section, setSection] = useState("");
    const { user, role } = useSelector((state) => state.auth);
    const { search } = useLocation();
    const [form, setForm] = useState({});

    useEffect(() => {
        const url = new URLSearchParams(search);
        const getSection = url.get("section");

        if (getSection) {
            setSection(getSection);
        } else {
            setSection("");
        }
    }, [search]);

    useEffect(() => {
        if (user) {
            setForm({
                sex: user.sex,
                name: user.name,
                surname: user.surname,
                birthday: user.birthday,
                email: user.email,
                phone: user.phone,
                institution: user.institution,
                emailNew: user.emailNew,
                telegram: user.telegram,
                schedule: user.schedule,
            });
        }
    }, [user]);

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const setSectionHandler = (section) => {
        if (!section) {
            history.push(`settings`);
        } else {
            history.push(`settings?section=${section}`);
        }
    };

    if (section === "personal") {
        return <YanaoComponent componentName={"PersonalDataYanao"}>
            <PersonalData />
        </YanaoComponent>;
    }

    if (section === "schedule") {
        return (
            <Layout>
                <div className="page">
                    <header className="page__header">
                        <button
                            onClick={() => setSectionHandler(null)}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        ></button>
                    </header>
                    <div className="page__content">
                        <h3>{t("work_schedule")}</h3>
                        <div className="auth-page__input-group">
                            <div className="input-field">
                                <TextareaAutosize
                                    className="textarea-autosize"
                                    placeholder={t("work_schedule")}
                                    id="schedule"
                                    name="schedule"
                                    onChange={changeHandler}
                                    value={form.schedule}
                                />
                                <label htmlFor="schedule">{t("work_schedule")}</label>
                            </div>
                        </div>
                        <p style={{ opacity: 0.5 }}>{t("work_schedule_description")}</p>
                    </div>
                    <button
                        onClick={() => dispatch(updateUser(form))}
                        style={{ marginTop: 20 }}
                        className="main-btn"
                    >
                        {t("save_button")}
                    </button>
                </div>
            </Layout>
        );
    }

    const logout = async () => {
        await axios.post("auth/logout");

        localStorage.removeItem("pwaUser");
        localStorage.removeItem("pwaPinSaved");

        dispatch({
            type: "AUTH/SET_USER",
            payload: {
                user: null,
                role: null,
            },
        });
        history.push("/");
    };

    return (
        <Layout>
            <div style={{ paddingBottom: "54px" }} className="page settings-page">
                <header className="page__header">
                    {/*<BackButton />*/}
                    <button
                        onClick={() => history.push("/dashboard")}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    />
                    <p className="page__title settings-page__title">{t("profile_settings")}</p>
                </header>
                <div className="settings-page__main">
                    {/*<button className="settings-page__btn">Изменить фото профиля</button>*/}
                </div>
                <div className="settings-page__sections">
                    <div className="settings-page__sections-wrapper">
                        <p className="settings-page__sections-title">{t("profile")}</p>
                        <div className="settings-sections">
                            <p
                                onClick={() => setSectionHandler("personal")}
                                className="settings-sections__item"
                            >
                                <PersonSVG />
                                {t("personal_info")}
                            </p>

                            {role === "psych" && (
                                <>
                                    <p
                                        onClick={() => setSectionHandler("schedule")}
                                        className="settings-sections__item"
                                    >
                                        <PersonSVG />
                                        {t("work_schedule")}
                                    </p>
                                    <Link
                                        to="/settings/security"
                                        className="settings-sections__item"
                                    >
                                        <LockSVG />
                                        {t("security")}
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="settings-page__footer">
                        <button
                            onClick={logout}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            className="logout-btn"
                        >
                            {t("logout")}
                        </button>
                        <p style={{ marginTop: "3rem", opacity: 0.3 }}>{t("help_text")}</p>
                        <p style={{ marginTop: "1rem", opacity: 0.3 }}>{t("suggestions_text")}</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
