import {RegisterPsychForm} from "../components/register/RegisterPsychForm";
import {RegisterPsychFormYanao} from "../yanaoDiff/components/RegisterPsychFormYanao/RegisterPsychFormYanao";
import {PupilsPage} from "../pages/pupils/PupilsPage";
import {PupilsPageYanao} from "../yanaoDiff/pages/PupilsPageYanao/PupilsPageYanao";
import { LandingContacts } from "components/landing";
import { LandingContactsYanao } from "yanaoDiff/components/LandingContactsYanao/LandingContactsYanao";
import { SupportButton } from "components/profile/SupportButton";
import { SupportButtonYanao } from "yanaoDiff/components/SupportButtonYanao/SupportButtonYanao";
import { SuggestionsPage } from "pages/SuggestionsPage";
import { SuggestionsPageYanao } from "yanaoDiff/pages/SuggestionsPageYanao/SuggestionsPageYanao";
import { PersonalDataYanao } from "yanaoDiff/components/PersonalDataYanao/PersonalDataYanao";
import { PersonalData } from "components/PersonalData";

export const yanaoComponents = {
    RegisterPsychForm: {
        main: RegisterPsychForm,
        yanao: RegisterPsychFormYanao,
    },
    LandingContactsYanao: {
        main: LandingContacts,
        yanao: LandingContactsYanao,
    },
    PupilsPageYanao: {
        main: PupilsPage,
        yanao: PupilsPageYanao,
    },
    SupportButtonYanao: {
        main: SupportButton,
        yanao: SupportButtonYanao,
    },
    SuggestionsPageYanao: {
        main: SuggestionsPage,
        yanao: SuggestionsPageYanao,
    },
    PersonalDataYanao: {
        main: PersonalData,
        yanao: PersonalDataYanao,
    },
};