import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import commentIcon from "static/img/ic-whatsapp.svg";

export const SupportButton = () => {
    const { t } = useTranslation("profile");

    return (
        <Link to="/suggestions" className="profile-page__psych-suggestions">
            <div>
                <p>{t("suggestions_btn_text")}</p>
            </div>
            <span>
                <img src={commentIcon} alt="" />
            </span>
        </Link>
    )
}