import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { saveLastLogin, updateUser } from "redux/actions/setUserActions";
import { selectUser } from "redux/authReducer";
import { AppRouter } from "app/AppRouter";
import { DownloadNotice } from "components/modals";
import { IS_YANAO } from "config";

function App() {
    const { user } = useSelector(selectUser);
    const { downloadFile } = useSelector((state) => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            (async () => saveLastLogin())();
        }
    }, [user]);

    //TODO: убрать 18.12.2024 TU-1128
    useEffect(() => {
        if(!IS_YANAO){
            return;
        }
        if(!user?.institutionID && user?.identifier) {
            const newUser = {...user, institutionID: user.identifier};
            dispatch(updateUser(newUser))
        }
    }, [user])

    return (
        <>
            <AppRouter />

            {downloadFile && <DownloadNotice />}
        </>
    );
}

export default App;
