import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import axios from "axios";

import { updateUser } from "redux/actions/setUserActions";
import { showAlert } from "redux/actions/setAppActions";
import { UPDATE_USER } from "redux/types";
import { TG_BOT_URL } from "config";
import { Loader } from "components//Loader";
import { Layout } from "components/Layout";
import { Input } from "components/forms/Input";


export const PersonalDataYanao = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation("settings");
    const [key, setKey] = useState("");
    const { user, role } = useSelector((state) => state.auth);
    const { loading } = useSelector((state) => state.app);
    const { search } = useLocation();
    const history = useHistory();
    const [form, setForm] = useState({});
    const [incorrectFields, setIncorrectFields] = useState([]);
    const formFields = {
        surname: t("surname"),
        name: t("name"),
        institutionID: t("institutionID"),
    };

    useEffect(() => {
        const url = new URLSearchParams(search);
        const getKey = url.get("key");

        if (getKey) {
            setKey(getKey);
        } else {
            setKey("");
        }
    }, [search]);

    const setUserForm = () => {
        if (user) {
            setForm({
                sex: user.sex,
                name: user.name,
                surname: user.surname,
                patronymic: user.patronymic,
                birthday: user.birthday,
                email: user.email,
                phone: user.phone,
                institution: user.institution,
                emailNew: user.emailNew,
                telegram: user.telegram,
                schedule: user.schedule,
                institutionID: user.institutionID,
                identifier: user.identifier,
            });
        }
    };

    useEffect(() => {
        setUserForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const changeHandler = (event) => {
        let { name, value } = event.target;

        if (incorrectFields.includes(name)) {
            setIncorrectFields(incorrectFields.filter((item) => item !== name));
        }

        if (["name", "surname"].includes(name) && value.length > 20) {
            return;
        }

        if (name === "telegram") {
            value = value.trim();
        }

        setForm({ ...form, [name]: value });
    };

    const saveUserHandler = () => {
        const errorFields = [];
        const forbiddenSymbols = /[%;="*?#&()_~]/gi;
        if (
            form.surname.length < 2 ||
            form.surname.length > 20 ||
            forbiddenSymbols.test(form.surname)
        )
            errorFields.push("surname");
        if (form.name.length < 2 || form.name.length > 20 || forbiddenSymbols.test(form.name))
            errorFields.push("name");

        if (!errorFields.length) {
            form.institutionID = form.institutionID.replace(" ", "").trim();
            if(form.institutionID !== ""){
                form.identifier = form.institutionID;
            }
            dispatch(updateUser(form));
        } else {
            const incorrectFieldsMessage = errorFields.map((item) => formFields[item]).join("\n");
            const text = `${t("incorrectly_filled_fields", {
                ns: "translation",
            })}:\n\n${incorrectFieldsMessage}`;

            setIncorrectFields(errorFields);
            dispatch(
                showAlert({
                    type: "error",
                    text,
                })
            );
        }
    };

    const changeKeyHandler = (key) => {
        if (role === "psych" || key === "telegram") {
            history.push(`settings?section=personal&key=${key}`);
        }
    };

    const backBtnHandler = () => {
        setUserForm();

        if (incorrectFields.length) {
            setIncorrectFields([]);
        }

        if (!key) {
            history.push(`settings`);
        } else {
            history.push(`settings?section=personal`);
        }
    };

    const updateUserHandler = async () => {
        try {
            const { data } = await axios.post(`${role}/update`, { ...form, id: user._id });
          
            if (data[role]) {
                dispatch({ type: UPDATE_USER, payload: data[role] });
                window.open(TG_BOT_URL, "_blank");
            } else {
                dispatch(
                    showAlert({
                        type: "error",
                        text: i18n.t("something_went_wrong", { ns: "translation" }),
                    })
                );
            }
        } catch (e) {
            const text =
                e?.response?.data?.message || i18n.t("something_went_wrong", { ns: "translation" });
            dispatch(showAlert({ type: "error", text }));
        }
    };

    const connectTg = () => {
        if (form.telegram !== "" && form.telegram === user.telegram) {
            window.open(TG_BOT_URL, "_blank");
        } else {
            updateUserHandler();
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <button
                        onClick={backBtnHandler}
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                    ></button>
                </header>

                {!user ? (
                    <Loader />
                ) : (
                    <>
                        <div className="page__content">
                            {!key && <h3>{t("personal_info")}</h3>}
                            {key === "sex" && <h3>{t("gender")}</h3>}
                            {key === "name" && <h3>{t("full_name")}</h3>}
                            {key === "birthday" && <h3>{t("birthday")}</h3>}
                            {key === "institutionID" && <h3>{t("institutionID")}</h3>}

                            {key === "sex" && (
                                <div className="auth-page__input-group auth-page__input-group_row">
                                    <div className="input-field">
                                        <input
                                            id="m"
                                            type="radio"
                                            checked={form.sex === "1"}
                                            name="sex"
                                            value="1"
                                            onChange={changeHandler}
                                        />
                                        <label htmlFor="m">{t("male")}</label>
                                    </div>
                                    <div className="input-field">
                                        <input
                                            id="f"
                                            type="radio"
                                            checked={form.sex === "2"}
                                            name="sex"
                                            value="2"
                                            onChange={changeHandler}
                                        />
                                        <label htmlFor="f">{t("female")}</label>
                                    </div>
                                </div>
                            )}

                            {key === "name" && (
                                <div className="auth-page__input-group">
                                    <Input
                                        id="surname"
                                        name="surname"
                                        placeholder={t("surname")}
                                        value={form.surname}
                                        onChange={changeHandler}
                                        isError={incorrectFields.includes("surname")}
                                    />
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder={t("name")}
                                        value={form.name}
                                        onChange={changeHandler}
                                        isError={incorrectFields.includes("name")}
                                    />
                                    <Input
                                        id="patronymic"
                                        name="patronymic"
                                        placeholder={t("patronymic")}
                                        value={form.patronymic}
                                        onChange={changeHandler}
                                    />
                                </div>
                            )}

                            {key === "birthday" && (
                                <div className="auth-page__input-group">
                                    <div className="input-field">
                                        <input
                                            id="birthday"
                                            type="date"
                                            name="birthday"
                                            onChange={changeHandler}
                                            value={moment(form.birthday).format("YYYY-MM-DD")}
                                        />
                                        <label htmlFor="birthday">{t("birthday")}</label>
                                    </div>
                                </div>
                            )}

                            {key === "emailNew" && (
                                <div className="auth-page__input-group">
                                    <h3 style={{ textAlign: "left" }}>{t("email")}</h3>
                                    <div className="input-field">
                                        <input
                                            placeholder="Почта"
                                            id="emailNew"
                                            type="text"
                                            name="emailNew"
                                            onChange={changeHandler}
                                            value={form.emailNew}
                                        />
                                        <label htmlFor="email">{t("email")}</label>
                                    </div>
                                </div>
                            )}

                            {key === "institutionID" && (
                                <div className="auth-page__input-group">
                                    <Input
                                        id="institutionID"
                                        name="institutionID"
                                        placeholder={`${t("institutionID")} (xxxx xxxx xxxx)`}
                                        value={form.institutionID}
                                        onChange={changeHandler}
                                    />
                                </div>
                            )}

                            {key === "telegram" && (
                                <div className="auth-page__input-group">
                                    <h3 style={{ textAlign: "left" }}>{t("telegram")}</h3>
                                    <div className="input-field">
                                        <input
                                            placeholder={t("telegram")}
                                            id="telegram"
                                            type="text"
                                            name="telegram"
                                            onChange={changeHandler}
                                            value={form.telegram}
                                        />
                                        <label htmlFor="email">{t("telegram")}</label>
                                    </div>
                                    <p className="settings-tg__description">
                                        {t("telegram_description")}
                                    </p>
                                </div>
                            )}
                            {!key && (
                                <>
                                    <ul className="settings-details">
                                        <li
                                            onClick={() => changeKeyHandler("sex")}
                                            className="settings-details__item"
                                        >
                                            <p>{t("gender")}</p>
                                            {!user.sex && <p>{t("gender_not_specified")}</p>}
                                            {!!user.sex && user.sex === "1" && <p>{t("male")}</p>}
                                            {!!user.sex && user.sex === "2" && <p>{t("female")}</p>}
                                        </li>
                                        <li
                                            onClick={() => changeKeyHandler("name")}
                                            className="settings-details__item"
                                        >
                                            <p>{t("full_name")}</p>
                                            <p>
                                                {user.surname} {user.name} {user.patronymic}
                                            </p>
                                        </li>
                                        <li
                                            onClick={() => changeKeyHandler("birthday")}
                                            className="settings-details__item"
                                        >
                                            <p>{t("birthday")}</p>
                                            <p>{moment(user.birthday).format("L")}</p>
                                        </li>
                                        {role === "psych" && (
                                            <>
                                                <li className="settings-details__item">
                                                    <p>{t("institution")}</p>
                                                    <p>{user.institution}</p>
                                                </li>
                                                <li className="settings-details__item">
                                                    <p>{t("phone")}</p>
                                                    <p>{user.phone}</p>
                                                </li>
                                                <li className="settings-details__item">
                                                    <p>{t("email")}</p>
                                                    <p>{user.email}</p>
                                                </li>
                                                <li
                                                    onClick={() => changeKeyHandler("telegram")}
                                                    className="settings-details__item"
                                                >
                                                    <p>{t("telegram")}</p>
                                                    <p>{user.telegram}</p>
                                                </li>
                                                <li
                                                    onClick={() => changeKeyHandler("institutionID")}
                                                    className="settings-details__item"
                                                >
                                                    <p>{t("institutionID")}</p>
                                                    <p>{user.institutionID}</p>
                                                </li>
                                            </>
                                        )}

                                        {role === "pupil" && (
                                            <li
                                                onClick={() => changeKeyHandler("telegram")}
                                                className="settings-details__item"
                                            >
                                                <p>{t("telegram")}</p>
                                                <p>{user.telegram}</p>
                                            </li>
                                        )}
                                    </ul>
                                    {role === "psych" && (
                                        <p style={{ marginTop: 20, opacity: 0.5 }}>
                                            {t("text_psych")}
                                        </p>
                                    )}

                                    {role === "pupil" && (
                                        <p style={{ marginTop: 20, opacity: 0.5 }}>
                                            {t("text_pupil")}
                                        </p>
                                    )}
                                </>
                            )}
                        </div>

                        {!!key && (
                            <button onClick={saveUserHandler} className="main-btn">
                                {t("save_button")}
                            </button>
                        )}

                        {key === "telegram" && (
                            <button
                                onClick={connectTg}
                                className="main-btn main-btn_green"
                                style={{ marginTop: 15 }}
                                disabled={!form.telegram}
                            >
                                {t("connect_tg_btn")}
                            </button>
                        )}
                    </>
                )}
            </div>
        </Layout>
    );
};
