import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";

import { CONSTRUCTOR_LINK } from "config.js";
import {
    LandingHeader,
    LandingHero,
    LandingContacts,
    LandingDocs,
    LandingForParents,
} from "components/landing";
import { InstallPwaPopup } from "components/InstallPwaPopup";
import { checkIsMobile, checkPwa } from "helpers/devices";
import FreemiumBanner from "./FreemiumBanner";
import phone from "../static/img/phone.png";
import psych1 from "../static/img/psych1.svg";
import psych2 from "../static/img/psych2.svg";
import attention from "../static/img/attention.svg";
import blocks from "../static/img/blocks.svg";
import close from "../static/img/close.svg";
import email from "../static/img/ic-email.svg";
import tel from "../static/img/ic-tel.svg";
import loc from "../static/img/ic-loc.svg";
import whiteLogo from "../static/img/whiteLogo.svg";
import diagram from "../static/img/diagram.svg";
import feather from "../static/img/feather.svg";
import check from "../static/img/check.svg";
import QR from "../static/img/QR.svg";
import books from "../static/img/books.png";
import bars from "../static/img/bars.svg";
import pc from "../static/img/pc.png";
import forPupil from "../static/img/forPupilImg.png";
import forSchool from "../static/img/forSchool.png";
import forPsych from "../static/img/forPsych.png";
import "../static/scss/landing.scss";
import { YanaoComponent } from "helpers/YanaoComponent";

/**
 * @api {get} / лендинг
 * @apiGroup main
 * @apiName лендинг
 * @apiDescription <p>Главная страница / лендинг</p>
 * <p>Компонент <code>LandingPage.js</code> <a href="../client/src/pages/LandingPage.js">ссылка на файл</a></p>
 */

const isPwa = checkPwa();

export const LandingPage = () => {
    const { t, i18n } = useTranslation("landing");
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useSelector((state) => state.auth);
    const [showPopup, setShowPopup] = useState(false);
    const [forWhatBlock, setForWhatBlock] = React.useState(1);
    const [isMenuOpened, setIsMenuOpened] = React.useState(false);
    const [isMenuLoginOpened, setIsMenuLoginOpened] = React.useState(false);
    const [isShowInstallPwaPopup, setIsShowInstallPwaPopup] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get("auth/user");
                dispatch({ type: "AUTH/SET_USER", payload: data });
            } catch (e) {}
        })();
    }, [dispatch]);

    useEffect(() => {
        if (isPwa && localStorage.getItem("pwaUser")) {
            history.push("/pincode");
        } else if (!Cookies.get("hidePwaInstallPopup") && checkIsMobile()) {
            setIsShowInstallPwaPopup(true);
        }
    }, [history]);

    function menuOpenHandler() {
        setIsMenuOpened(true);
    }

    function menuCloseHandler() {
        setIsMenuOpened(false);
        setIsMenuLoginOpened(false);
    }

    function menuLoginOpenHandler() {
        setIsMenuLoginOpened(true);
    }

    const showPopupHandler = useCallback(() => {
        setShowPopup(!showPopup);
    }, [showPopup]);

    const showInstallPwaPopupHandler = useCallback(() => {
        setIsShowInstallPwaPopup(true);
    }, []);

    const hideInstallPwaPopupHandler = useCallback(() => {
        setIsShowInstallPwaPopup(false);
    }, []);

    return (
        <div className="landing">
            {isShowInstallPwaPopup && <InstallPwaPopup closePopup={hideInstallPwaPopupHandler} />}

            <LandingHeader user={user} t={t} showPopup={showPopupHandler} />
            <LandingHero user={user} t={t} lang={i18n.language} showPopup={showPopupHandler} />

            <section className="landing-about" id="landing-about">
                <h2 className="landing-about__title-center">{t("about_title")}</h2>
                <div className="landing-about__row">
                    <div className="landing-about__leftCol">
                        <div className="landing-about__prop">
                            <img src={QR} alt="" />
                            <p>{t("about_list_1")}</p>
                        </div>
                        <div className="landing-about__prop">
                            <img src={check} alt="" />
                            <p>{t("about_list_2")}</p>
                        </div>
                        <div className="landing-about__prop">
                            <img src={blocks} alt="" />
                            <p>{t("about_list_3")}</p>
                        </div>
                    </div>
                    <div className="landing-about__middleCol">
                        <img src={phone} alt="" />
                    </div>
                    <div className="landing-about__rightCol">
                        <div className="landing-about__prop">
                            <img src={diagram} alt="" />
                            <p>{t("about_list_4")}</p>
                        </div>
                        <div className="landing-about__prop">
                            <img src={attention} alt="" />
                            <p>{t("about_list_5")}</p>
                        </div>
                        <div className="landing-about__prop">
                            <img src={feather} alt="" />
                            <p>{t("about_list_6")}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="landing-services" id="landing-services">
                <LandingDocs t={t} />

                <h2 id="forWhatAnchor" className="landing-services__title">
                    {t("why_title")}
                </h2>
                <div className="landing-services__buttons">
                    <button
                        className={
                            forWhatBlock === 1
                                ? "landing-services__button-active"
                                : "landing-services__button"
                        }
                        onClick={() => {
                            setForWhatBlock(1);
                        }}
                    >
                        {t("why_button_management")}
                    </button>
                    <button
                        className={
                            forWhatBlock === 2
                                ? "landing-services__button-active"
                                : "landing-services__button"
                        }
                        onClick={() => {
                            setForWhatBlock(2);
                        }}
                    >
                        {t("why_button_psychologist")}
                    </button>
                    <button
                        className={
                            forWhatBlock === 3
                                ? "landing-services__button-active"
                                : "landing-services__button"
                        }
                        onClick={() => {
                            setForWhatBlock(3);
                        }}
                    >
                        {t("why_button_pupil")}
                    </button>
                </div>
                <div className="landing-services__blocksContent">
                    {forWhatBlock === 1 && (
                        <div className="landing-services__blockContent">
                            <div className="landing-services__blockText">
                                <div className="rowDot">
                                    <div className="dot-blue" />
                                    <p className="p-390">{t("why_management_list_1")}</p>
                                </div>
                                <div className="rowDot">
                                    <div className="dot-blue" />
                                    <p className="p-390">{t("why_management_list_2")}</p>
                                </div>
                                <div className="rowDot">
                                    <div className="dot-blue" />
                                    <p className="p-390">{t("why_management_list_3")}</p>
                                </div>
                                <button
                                    onClick={() => {
                                        showPopupHandler();
                                    }}
                                >
                                    {t("button_try_for_free")}
                                </button>
                            </div>
                            <img src={forSchool} alt="" />
                        </div>
                    )}

                    {forWhatBlock === 2 && (
                        <div className="landing-services__blockContent">
                            <div className="landing-services__blockText">
                                <div className="rowDot">
                                    <div className="dot-yellow" />
                                    <p className="p-475">{t("why_psychologist_list_1")}</p>
                                </div>
                                <div className="rowDot">
                                    <div className="dot-yellow" />
                                    <p className="p-390">{t("why_psychologist_list_2")}</p>
                                </div>
                                <div className="rowDot">
                                    <div className="dot-yellow" />
                                    <p className="p-475">{t("why_psychologist_list_3")}</p>
                                </div>
                                <div className="rowDot">
                                    <div className="dot-yellow" />
                                    <p className="p-475">{t("why_psychologist_list_4")}</p>
                                </div>
                                <div className="rowDot">
                                    <div className="dot-yellow" />
                                    <p className="p-475">{t("why_psychologist_list_5")}</p>
                                </div>
                                <button
                                    onClick={() => {
                                        showPopupHandler();
                                    }}
                                >
                                    {t("button_try_for_free")}
                                </button>
                            </div>
                            <img src={forPsych} alt="" />
                        </div>
                    )}

                    {forWhatBlock === 3 && (
                        <div className="landing-services__blockContent">
                            <div className="landing-services__blockText">
                                <div className="rowDot">
                                    <div className="dot-red" />
                                    <p className="p-390">{t("why_pupil_list_1")}</p>
                                </div>
                                <div className="rowDot">
                                    <div className="dot-red" />
                                    <p className="p-390">{t("why_pupil_list_2")}</p>
                                </div>
                                <div className="rowDot">
                                    <div className="dot-red" />
                                    <p className="p-390">{t("why_pupil_list_3")}</p>
                                </div>
                                <div className="rowDot">
                                    <div className="dot-red" />
                                    <p className="p-390">{t("why_pupil_list_4")}</p>
                                </div>
                                <button
                                    onClick={() => {
                                        showPopupHandler();
                                    }}
                                >
                                    {t("button_try_for_free")}
                                </button>
                            </div>
                            <img src={forPupil} alt="" />
                        </div>
                    )}

                    <button
                        className="forMob"
                        onClick={() => {
                            showPopupHandler();
                        }}
                    >
                        {t("button_try_for_free")}
                    </button>
                </div>
                <div id="quizAnchor" className="quiz">
                    <h2 className="landing-services__title">{t("our_methodology")}</h2>
                    <p className="landing-services__method-text">{t("our_methodology_subtitle")}</p>
                    <p className="landing-services__quiz-text">{t("our_methodology_list_title")}</p>
                    <div className="landing-services__quizRow">
                        <div className="col">
                            <div className="numberHolder-small">03</div>
                            <p>{t("our_methodology_list_1")}</p>
                        </div>
                        <div className="col">
                            <div className="numberHolder-small">30</div>
                            <p>{t("our_methodology_list_2")}</p>
                        </div>
                        <div className="col">
                            <div className="numberHolder-big">300+</div>
                            <p>{t("our_methodology_list_3")}</p>
                        </div>
                    </div>
                </div>
                <h2 className="landing-services__title">{t("our_methodologists_title")}</h2>
                <div className="landing-services__methodologistRow">
                    <div className="landing-services__methodologist">
                        <h3>
                            {t("our_methodologists_1_surname")} <br />
                            {t("our_methodologists_1_name")} <br />
                            {t("our_methodologists_1_patronymic")}
                        </h3>
                        <ul>
                            <li>
                                {t("our_methodologists_desc_psych")},{" "}
                                <span>{t("our_methodologists_desc_phd")}</span>
                            </li>
                            <li>
                                <span>{t("our_methodologists_desc_1a")}</span>{" "}
                                {t("our_methodologists_desc_1b")}{" "}
                                <span>{t("our_methodologists_desc_1c")}</span>
                            </li>
                            <li>
                                {t("our_methodologists_desc_2a")} -{" "}
                                <span>{t("our_methodologists_desc_2b")}</span>
                            </li>
                        </ul>
                        <img src={psych2} alt="" />
                    </div>
                    <div className="landing-services__methodologist">
                        <h3>
                            {t("our_methodologists_2_surname")} <br />
                            {t("our_methodologists_2_name")} <br />
                            {t("our_methodologists_2_patronymic")}
                        </h3>
                        <ul>
                            <li>
                                {t("our_methodologists_desc_psych")},{" "}
                                <span>{t("our_methodologists_desc_phd")}</span>
                            </li>
                            <li>
                                <span>{t("our_methodologists_desc_1a")}</span>{" "}
                                {t("our_methodologists_desc_1b")}{" "}
                                <span>{t("our_methodologists_desc_1c")}</span>
                            </li>
                            <li>
                                {t("our_methodologists_desc_2a")} -{" "}
                                <span>{t("our_methodologists_desc_2b")}</span>
                            </li>
                        </ul>
                        <img src={psych1} alt="" />
                    </div>
                </div>
                <div className="landing-services__contentBlock">
                    <img src={books} alt="" />
                    <div>
                        <h4>{t("co_authors_title")}</h4>
                        <ul>
                            <li>{t("co_authors_list_1")}</li>
                            <li>{t("co_authors_list_2")}</li>
                            <li>{t("co_authors_list_3")}</li>
                            <li>{t("co_authors_list_4")}</li>
                        </ul>
                    </div>
                </div>
                <div className="landing-services__contentBlock landing-services__contentBlock-blue">
                    <img src={pc} alt="" />
                    <div>
                        <h4>{t("participants_title")}</h4>
                        <ul>
                            <li>{t("participants_list_1")}</li>
                            <li>{t("participants_list_2")}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="landing-goal" id="landing-goal">
                <h2 className="landing-goal__title">{t("about_2_title")}</h2>
                <div className="landing-goal__list">
                    <p>
                        {t("about_2_text_1a")} <span>{t("about_2_text_1b")}</span>{" "}
                        {t("about_2_text_1c")}, <span>{t("about_2_text_1d")}</span>,{" "}
                        {t("about_2_text_1e")}
                    </p>
                    <p>
                        {t("about_2_text_2a")} <span>{t("about_2_text_2b")}</span>,{" "}
                        {t("about_2_text_2c")}, <span>{t("about_2_text_2d")}</span>{" "}
                        {t("about_2_text_2e")}
                    </p>
                    <label>
                        {t("about_2_signature_1")},
                        <br />
                        {t("about_2_signature_2")}
                    </label>
                </div>

                <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
                    <a
                        href="https://labssd.ru"
                        target="_blank"
                        rel="noreferrer"
                        className="landing-main__btn"
                    >
                        Сайт компании
                    </a>
                </div>
            </section>

            <LandingForParents t={t} />
            <YanaoComponent componentName="LandingContactsYanao">
                <LandingContacts showInstallPwaPopupHandler={showInstallPwaPopupHandler} />
            </YanaoComponent>

            <section
                style={showPopup ? { display: "flex" } : { display: "none" }}
                className="landing-popup"
                id="landing-popup"
            >
                <FreemiumBanner showPopup={showPopupHandler} />
            </section>
            <section className={isMenuOpened ? "opened menu" : "menu"} id="menu">
                <div className="menu__row">
                    <img src={whiteLogo} alt="" />
                    <button
                        onClick={() => {
                            menuCloseHandler();
                        }}
                        className="menu__close"
                    >
                        <img src={close} alt="" />
                    </button>
                </div>
                <div className="menu__content">
                    <div
                        onClick={() => {
                            menuCloseHandler();
                        }}
                        className="menu__links"
                    >
                        <a href={"#landing-main"}>{t("home")}</a>
                        <a href={"#landing-about"}>{t("our_solution")}</a>
                        <a href={"#forWhatAnchor"}>{t("for_whom")}</a>
                        <a href={"#quizAnchor"}>{t("our_methodology")}</a>
                        <a href={"#landing-goal"}>{t("about")}</a>
                    </div>
                    <div
                        style={isMenuLoginOpened ? { marginTop: "40px" } : { display: "none" }}
                        onClick={() => {
                            menuCloseHandler();
                        }}
                        className="menu__links hidden"
                    >
                        <a href={CONSTRUCTOR_LINK}>{t("button_constructor")}</a>
                        <Link to="/pupil/login">{t("button_pupils_login")}</Link>
                        <Link to="/psych/login">{t("button_psych_login")}</Link>
                    </div>
                    <button
                        style={isMenuLoginOpened ? { display: "none" } : {}}
                        onClick={() => {
                            menuLoginOpenHandler();
                        }}
                    >
                        <div className="menu__loginBtn">{t("button_login")}</div>
                    </button>
                    <button
                        onClick={() => {
                            showPopupHandler();
                        }}
                    >
                        <div className="menu__freeBtn">{t("start_free")}</div>
                    </button>

                    <div className="menu__contacts">
                        <div className="menu__contacts-row">
                            <div className="menu__contacts-imgHolder">
                                <img src={email} alt="" />
                            </div>
                            <a href="mailto:ceo@testu.online">ceo@testu.online</a>
                        </div>
                        <div className="menu__contacts-row">
                            <div className="menu__contacts-imgHolder">
                                <img src={tel} alt="" />
                            </div>
                            <a href="tel:+74951207004">{t("footer_phone")}</a>
                        </div>
                        <div className="menu__contacts-row">
                            <div className="menu__contacts-imgHolder">
                                <img src={loc} alt="" />
                            </div>
                            <a href="https://yandex.ru/maps/213/moscow/house/bersenevskaya_naberezhnaya_6s3/Z04YcAdpS0MEQFtvfXt1cXpmYA==/?ll=37.609263%2C55.740654&z=17.23">
                                {t("footer_address_1")} {t("footer_address_2")}
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <img
                onClick={() => {
                    menuOpenHandler();
                }}
                id="menuOpener"
                src={bars}
                alt=""
            />
        </div>
    );
};
