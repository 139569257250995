import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useThrottle } from "hooks/useThrottle";
import { fetchRegionsList } from "services/fetchRegionsList";

export const RegisterPsychRegionSelect = ({ setRegion }) => {
    const { t } = useTranslation();
    const [input, setInput] = useState("");
    const throttledText = useThrottle(input, 500);
    const [locations, setLocations] = useState([]);

    const setCitiesList = async () => {
        const locationsList = await fetchRegionsList(throttledText);

        if (locationsList) {
            const result = locationsList.suggestions
                .map(
                    ({
                        data: { fias_id, region_with_type, city_with_type, settlement_with_type },
                    }) => {
                        if (!city_with_type && !settlement_with_type) {
                            return null;
                        }

                        const value = settlement_with_type ? settlement_with_type : city_with_type;

                        return {
                            id: fias_id,
                            region: region_with_type,
                            city: city_with_type || settlement_with_type,
                            value: `${value}, ${region_with_type}`,
                        };
                    }
                )
                .filter((data) => Boolean(data));

            if (result) {
                setLocations(result);
            }
        }
    };

    useEffect(() => {
        if (throttledText.trim() !== "") {
            setCitiesList();
        } else {
            setLocations([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [throttledText]);

    const changeInputHandler = (e) => {
        setInput(e.target.value);
    };

    return (
        <>
            <div className="auth-page__register-header">
                <h3>{t("choose_region")}</h3>
            </div>
            <div className="region-content">
                <div className="region-find">
                    <input
                        type="text"
                        value={input}
                        onChange={changeInputHandler}
                        placeholder="пример: г Москва"
                        autoFocus
                    />
                </div>
                <div>
                    {locations.map(({ id, region, city, value }) => (
                        <button
                            className="region-select-btn"
                            onClick={() => {
                                setRegion(region, city);
                            }}
                            key={id}
                        >
                            {value}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};
