import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import phoneIcon from "static/img/ic-phone.svg";
import commentIcon from "static/img/ic-whatsapp.svg";
import './modifications.css'

export const SupportButtonYanao = () => {

    const { t } = useTranslation("profile");
    const handleClick = (e) => {
        e.stopPropagation();
    }
    return (
        <Link to={"/suggestions"} className="profile-page__psych-suggestions">
            <div>
                <p>{t("suggestions_btn_text_yanao")}</p>
                <p  className="profile-page__psych-suggestions__phone">{t("suggestions_btn_phone_yanao")}</p>
            </div>
            <div className="profile-page__psych-suggestions__row">
                <a href="tel:74951207004" onClick={handleClick}>
                    <span>
                        <img src={phoneIcon} alt="" />
                    </span>
                </a>
                <Link to="/suggestions">
                    <span>
                        <img src={commentIcon} alt="" />
                    </span>
                </Link>
            </div>
        </Link>
    )
}